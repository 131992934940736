'use client';

import font from '@/helpers/utils/fonts';
import TextLink from '@/components/atoms/TextLink/TextLink';
import HeadingOne from '@/components/atoms/HeadingOne/HeadingOne';
import Paragraph from '@/components/atoms/Paragraph/Paragraph';
import PUBLICATION from '@/helpers/utils/publication';
import CTA from '@/components/atoms/CTA/CTA';

export default function Error({ reset }: { reset: () => void }) {
  return (
    <div className={font(PUBLICATION)}>
      <HeadingOne publication={PUBLICATION}>Something went wrong!</HeadingOne>
      <Paragraph>
        Please return to <TextLink href="/">the homepage</TextLink>. or
      </Paragraph>
      <p>
        <CTA design="primary" label="Try again" ariaLabel="Try again" onClick={() => reset()} />
      </p>
    </div>
  );
}
